.backcolor{
    background-color: black;
}
.loggo{
  width:9vw;
}

.aboutrs{
    background-color:#3C3C3C ;
}
.why{
    background-image: url(../Assets/Why\ CHose\ Us.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.hero{
    background-image: url("../Assets/Hero.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 110vh;
}

.hero2{
    background-color: white;
    border-radius: 10px;
}

.avatar-placeholder {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .avatar-placeholder img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .app-container {
    background-image: url('../Assets/Why\ CHose\ Us.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 120vh;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  .glassmorphism-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .form-section {
    background: rgba(255, 255, 255, 0.8);
    /* padding: 20px; */
    border-radius: 8px;
  }
  
  .info-section {
    background: #333;
    color: white;
    padding: 20px;
    /* border-radius: 8px; */
  }
  
  .info-section h3 {
    margin: 0;
  }
  
  .info-section p {
    margin: 0;
  }
  
  .text-center {
    text-align: center;
  }
  
  .boxes{
    background-color: #FFFFFF;
    border-radius: 10px;
  }

.buton{
  border-radius: 10px;
  background: #D6D6D6;
  border: none;
}
.buton2{
  border-radius: 10px;
  background: #676767;
  border: none;
}

@media only  screen and (max-width:576px) {
  .hero{
    background-image: url("../Assets/Hero.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 165vh;
}
.loggo{
  width:20vw;
}
}

.outlin {
  border: 2px solid #C7C7C7; 
  padding: 10px; 
  border-radius: 5px; 
}
.outlin:hover{
  background: black;
}

.coloni:hover{
  color: white;
}
.sub{
  border: none;
  border-radius:1vw ;
    font-size: 1.3vw;
}
.menu{
  background-color: black;
  height: 100vh;
}

::placeholder{
  color: #B5B5B5;
}
.show{
  border: none;
  border-radius: 1vw;
}


